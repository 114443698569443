import { useLayout } from '@/hooks/Contexts'
import { useClickOutside, useScrollable } from '@/hooks/Index'
import { svgs } from '@/utils/Images'
import { Action0, Action1 } from '@/utils/Types'
import { useRouter } from 'next/router'
import { FC, Fragment, useCallback, useMemo, useRef, useState } from 'react'
import CaretIcon from '../CaretIcon'

type Tab = { href: string; text: string } & Record<string, any>
interface NavigationTabProps {
  tabs: Array<Tab>
  svgKey?: string
  extraCls?: string
}

export const NavigationTab: FC<NavigationTabProps & { logout?: Action0 }> = ({ logout, tabs, ...rest }) => {
  const { isDesktop } = useLayout()
  const router = useRouter()
  const onClick = useCallback((href: string) => {
    if (href == 'logout') {
      logout?.()
    } else {
      router.push(href)
    }
  }, [])
  const selected: Tab = useMemo(() => tabs?.find?.(tab => router.pathname?.startsWith?.(tab.href)), [router.isReady, router.pathname, tabs])

  return (
    <Fragment>
      {isDesktop ? (
        <DesktopNavTab {...rest} tabs={tabs} selected={selected} onClick={onClick} />
      ) : (
        <MobileNavTab {...rest} tabs={tabs} selected={selected} onClick={onClick} />
      )}
    </Fragment>
  )
}

const DesktopNavTab: FC<NavigationTabProps & { selected?: Tab; onClick?: Action1<string> }> = ({ tabs, svgKey, extraCls, selected, onClick }) => {
  return (
    <div className={`relative z-[1] h-auto ${extraCls || ''}`}>
      <div className='flex w-full max-w-fit items-center overflow-hidden rounded-lg bg-white/100 ring-2 ring-inset ring-white/100'>
        {tabs.map((tab, index) => {
          const isSelected = tab.href == selected?.href
          return (
            <div key={'tab-' + index} className='group relative flex cursor-pointer'>
              <div
                className={`absolute left-0 top-0 h-full rounded-lg ${
                  isSelected ? 'w-full border-2 border-amber-v1' : 'w-0 transition-all duration-300 group-hover:w-full group-hover:bg-amber-100/75'
                }`}
              />
              <div onClick={() => onClick?.(tab.href)} data-testid={tab.testid} className='relative flex flex-1 items-center px-4 py-2 lg:px-6'>
                {svgKey && tab.icon ? <span className={`mr-2 h-4 w-4 ${isSelected ? 'text-amber-v1' : 'text-cyan-v2'}`}>{svgs[svgKey][tab.icon]}</span> : null}
                <span className='whitespace-nowrap text-sm font-semibold capitalize'>{tab.text}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const MobileNavTab: FC<NavigationTabProps & { selected?: Tab; onClick?: Action1<string> }> = ({ tabs, svgKey, extraCls, selected, onClick }) => {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef(null)
  useClickOutside(wrapperRef, () => setIsOpen(false))
  useScrollable(isOpen)
  return (
    <Fragment>
      {isOpen ? <div className='fixed inset-0 z-[8] h-screen w-screen bg-transparent ease-in-out'></div> : null}
      <div ref={wrapperRef} className={`relative z-[9] h-auto ${extraCls || ''}`}>
        <div
          className={`flex cursor-pointer items-center border-2 bg-white/100 px-4 py-2 capitalize shadow shadow-stone-200 outline-none transition ${
            isOpen ? 'rounded-t-lg border-x-stone-200 border-b-transparent border-t-stone-200' : 'rounded-lg border-stone-200 hover:bg-amber-100'
          }`}
          onClick={() => setIsOpen(prev => !prev)}
        >
          {selected?.icon ? <span className='mr-4 flex h-4 w-4 text-cyan-v2'>{svgs[svgKey]?.[selected?.icon]}</span> : null}
          <span className='mr-auto'>{selected?.text}</span>
          <CaretIcon contentOpen={isOpen} />
        </div>
        <div
          className={`absolute top-full w-full rounded-b-lg border-x-2 border-b-2 border-stone-200 transition ${
            isOpen ? 'opacity-100' : 'pointer-events-none -translate-y-[10px] opacity-0'
          }`}
        >
          {tabs.map((tab, index) => (
            <button
              key={`tab-btn-${tab.text}-${index}`}
              onClick={() => {
                setIsOpen(false)
                onClick?.(tab.href)
              }}
              disabled={tab.href == selected?.href}
              className='group flex w-full items-center border-b bg-white/100 px-4 py-2 text-left transition first:border-t last:rounded-b-lg last:border-0 enabled:hover:bg-amber-v1 disabled:bg-amber-v1 disabled:font-medium'
              data-testid={tab.testid}
            >
              {svgKey && tab.icon && (
                <span className='mr-4 h-4 w-4 group-enabled:text-cyan-v2 group-enabled:group-hover:text-black group-disabled:text-black'>
                  {svgs[svgKey][tab.icon]}
                </span>
              )}
              <span className='capitalize'>{tab.text}</span>
            </button>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default NavigationTab
