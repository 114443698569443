export const floatAddition = (arg1: number, arg2: number) => {
  if (arg1 == 0) return arg2
  if (arg2 == 0) return arg1
  const str1 = floatToString(arg1)
  const str2 = floatToString(arg2)
  const digitLen1 = str1.split('.')[1]?.length ?? 0
  const digitLen2 = str2.split('.')[1]?.length ?? 0
  const power = Math.pow(10, Math.max(digitLen1, digitLen2))
  const diff = Math.abs(digitLen1 - digitLen2)
  if (diff > 0) {
    const diffPower = Math.pow(10, diff)
    if (digitLen1 > digitLen2) {
      arg1 = Number(str1.replace('.', ''))
      arg2 = Number(str2.replace('.', '')) * diffPower
    } else {
      arg1 = Number(str1.replace('.', '')) * diffPower
      arg2 = Number(str2.replace('.', ''))
    }
  } else {
    arg1 = Number(str1.replace('.', ''))
    arg2 = Number(str2.replace('.', ''))
  }
  return (arg1 + arg2) / power
}

export const floatSubtraction = (arg1: number, arg2: number) => {
  if (arg2 == 0) return arg1
  const digitLen1 = floatToString(arg1).split('.')[1]?.length ?? 0
  const digitLen2 = floatToString(arg2).split('.')[1]?.length ?? 0
  const fixed = digitLen1 >= digitLen2 ? digitLen1 : digitLen2
  const power = Math.pow(10, fixed)
  return ((arg1 * power - arg2 * power) / power).toLocaleString('en-US', {
    useGrouping: false,
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed > 20 ? 20 : fixed,
  })
}

export const floatMultiplication = (arg1: number, arg2: number) => {
  if (arg1 == 0 || arg2 == 0) return 0
  if (arg1 == 1) return arg2
  if (arg2 == 1) return arg1
  let digitLen = 0
  const str1 = floatToString(arg1)
  const str2 = floatToString(arg2)
  digitLen += str1.split('.')[1]?.length ?? 0
  digitLen += str2.split('.')[1]?.length ?? 0
  return (Number(str1.replace('.', '')) * Number(str2.replace('.', ''))) / Math.pow(10, digitLen)
}

export const floatDivision = (arg1: number, arg2: number) => {
  if (arg1 == 0 || arg2 == 0) return 0
  if (arg2 == 1) return arg1
  const str2 = floatToString(arg2)
  const divisor = Number(str2.replace('.', ''))
  if (divisor == 0) {
    return 0
  } else {
    const str1 = floatToString(arg1)
    const digitLen1 = str1.split('.')[1]?.length ?? 0
    const digitLen2 = str2.split('.')[1]?.length ?? 0
    const dividend = Number(str1.replace('.', ''))
    const power = Math.pow(10, digitLen2 - digitLen1)
    return (dividend / divisor) * power
  }
}

export const notNull = arg => {
  return typeof arg != 'undefined' && arg != null && !isNaN(arg)
}

export const floatToString = (arg: string | number) => {
  return typeof arg == 'string' ? arg : arg.toLocaleString('en-US', { useGrouping: false, maximumFractionDigits: 20 })
}

export const toIntString = (arg: string | number, useGrouping = false) => {
  const number = notNull(arg) ? (typeof arg == 'number' ? arg : Number(arg)) : 0
  return number.toLocaleString('en-US', { useGrouping, minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

export const toPercent = (n: number) => {
  if (!n?.toString() || isNaN(n)) return
  return Math.round((n * 100 + Number.EPSILON) * 100) / 100
}

const BIG_NUM_FORMATS = [
  { value: 1e12, symbol: 'T' },
  { value: 1e9, symbol: 'B' },
  { value: 1e6, symbol: 'M' },
  { value: 1e3, symbol: 'K' },
]
export const formatBigNum = (num: number) => {
  const format = BIG_NUM_FORMATS.find(f => Math.abs(num) >= f.value)
  if (format) {
    const formatted = (num / format.value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    const parts = formatted.match(/([\D]*)([\d.,]+)([\D]*)/)
    return `${parts[1]}${parts[2]}${format.symbol}${parts[3]}`
  } else {
    return num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }
}

export const formatWithGBP = (val: string | number, digits?: number) => {
  const number = notNull(val) ? (typeof val == 'number' ? val : Number(val)) : 0
  const fractionDigits = notNull(digits) ? (digits > 20 ? 20 : digits < 2 ? 2 : digits) : 2
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}
