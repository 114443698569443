import { svgs } from '@/utils/Images'
import { Action0 } from '@/utils/Types'
import { FC, HTMLAttributes, ReactElement } from 'react'
import IconButton from './IconButton'

export const SliderArrows: FC<
  HTMLAttributes<HTMLDivElement> & {
    prevClick: Action0
    nextClick: Action0
    isFirst?: boolean
    isLast?: boolean
    btnCls?: string
  }
> = ({ isFirst, isLast, prevClick, nextClick, className, btnCls, children, ...rest }): ReactElement => {
  return (
    <div {...rest} className={`flex items-center ${className || ''}`}>
      <IconButton className={'h-8 w-8 p-1.5 ' + btnCls} disabled={isFirst} onClick={prevClick}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 18' fill='none' className='h-full w-2.5'>
          {svgs.arrow_prev}
        </svg>
      </IconButton>
      {children}
      <IconButton className={'h-8 w-8 p-1.5 ' + btnCls} disabled={isLast} onClick={nextClick}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 18' fill='none' className='h-full w-2.5'>
          {svgs.arrow_next}
        </svg>
      </IconButton>
    </div>
  )
}
export default SliderArrows
