import { useAuth } from '@/hooks/Auth'
import { useLanguage } from '@/hooks/Contexts'
import Head from 'next/head'
import { FC, Fragment, PropsWithChildren, useMemo } from 'react'
import NavigationTab from '../Navigation/NavigationTab'

export const UserLayout: FC<PropsWithChildren<{ title?: string; pageDescription?: string; bodyCls?: string }>> = ({
  title,
  pageDescription,
  bodyCls,
  children,
}) => {
  const { navigation: navStr } = useLanguage()
  const titleStr = title ? title + ' - ' + navStr.app_name : navStr.app_name
  return (
    <Fragment>
      <Head>
        <title>{titleStr}</title>
        <meta name='title' content={titleStr} key='title' />
        <meta property='og:title' content={titleStr} key='og:title' />
        <meta name='twitter:title' content={titleStr} key='twitter:title' />
        {pageDescription ? (
          <>
            <meta name='description' content={pageDescription} key='description' />
            <meta name='twitter:description' content={pageDescription} key='twitter:description' />
            <meta name='og:description' content={pageDescription} key='og:description' />
          </>
        ) : null}
      </Head>
      <div className='relative mt-16 bg-white/100 shadow-[3px_4px_15px_0px_#0000000D] md:mt-20'>
        <div className='container-white text-xl font-medium capitalize md:text-2xl'>{navStr.my_account}</div>
      </div>
      <div className='fluid-gray'>
        <UserNav />
        <div className={`container-gray mt-4 min-h-[120px] animate-fade-in-down flex-col ${bodyCls || ''}`}>{children}</div>
      </div>
    </Fragment>
  )
}

// const optionKeys = ['dashboard', 'wallet', 'orders', 'details', 'addresses']
const UserNav: FC = () => {
  const { navigation: navStr, user: userStr, auth: authStr } = useLanguage()
  const { user, logout } = useAuth({ middleware: 'auth' })
  const options = useMemo(() => {
    let arr = []
    if (user) {
      arr = [
        { href: '/user/dashboard', icon: 'dashboard', text: navStr.account_centre, testid: 'btn-myAcc-accCentre' },
        { href: '/user/wallet', icon: 'wallet', text: userStr.my_wallet, testid: 'btn-myAcc-wallet' },
        // { href: '/user/competitions', icon: 'competitions', text: userStr.my_competitions, testid: 'btn-myAcc-comp' },
        { href: '/user/orders', icon: 'orders', text: userStr.my_orders, testid: 'btn-myAcc-orders' },
        { href: '/user/details', icon: 'details', text: navStr.my_account, testid: 'btn-myAcc-accDetail' },
        { href: '/user/addresses', icon: 'addresses', text: userStr.my_address, testid: 'btn-myAcc-address' },
        { href: 'logout', icon: 'logout', text: authStr.logout, testid: 'btn-myAcc-logout' },
      ]
      if (user.is_affiliate) {
        arr.splice(5, 0, { href: '/user/affiliate', icon: 'affiliate', text: userStr.referrals, testid: 'btn-myAcc-affiliate' })
      }
    }
    return arr
  }, [userStr, user])
  return <NavigationTab tabs={options} svgKey='user_nav' extraCls='mx-2 xl:container sm:mx-4 md:mx-auto md:px-4' logout={() => logout()} />
}

export default UserLayout
